import { useEffect } from "react";
import { animate, MotionValue, useMotionValue } from "framer-motion";
import interpolate from "flubber/src/interpolate";

const useSVGPathMorph = (
  path: string,
  animationSettings: Record<string, unknown> = {}
): MotionValue<string> => {
  const value = useMotionValue<string>(path);

  useEffect(() => {
    const interpolator = interpolate(value.get(), path, {
      maxSegmentLength: false,
    });

    const controls = animate(0, 1, {
      ...animationSettings,
      onUpdate: (progress) => value.set(interpolator(progress)),
    });

    return () => controls.stop();
  }, [animationSettings, path]);

  return value;
};

export default useSVGPathMorph;
