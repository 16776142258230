import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import MemberSVG from "../MemberSVG";

export const query = graphql`
  query MembersNew {
    placeholder: file(relativePath: { eq: "images/template/placeholder.svg" }) {
      publicURL
    }
    socialSVG: file(relativePath: { eq: "images/team/social.svg" }) {
      publicURL
    }
    bioSVG: file(relativePath: { eq: "images/team/bio.svg" }) {
      publicURL
    }
    closeSVG: file(relativePath: { eq: "images/team/close.svg" }) {
      publicURL
    }
  }
`;

const MOBILE_BREAKPOINT = 991;

const Member = (props: any) => {
  const data = useStaticQuery(query);
  const placeholder = data.placeholder?.publicURL || [];
  //const socialSVG = data.socialSVG?.publicURL || [];
  const bioSVG = data.bioSVG?.publicURL || [];
  const closeSVG = data.closeSVG?.publicURL || [];
  const offScreenPosition = { x: 0, y: -720 }; // MemberFloatingBio Max-width: 360px

  const [isHovered, setIsHovered] = useState(false); // desktop
  const [floatingBioPosition, setFloatingBioPosition] =
    useState(offScreenPosition);
  const [isBioOpen, setIsBioOpen] = useState(false);

  const handleMouseMove = (e) => {
    if (!isHovered) {
      return;
    }
    setFloatingBioPosition({
      x: e.pageX + 10,
      y: e.pageY + 10,
    });
  };

  const handleOpenBio = (e) => {
    e.stopPropagation();
    setIsBioOpen(!isBioOpen);
  };

  const handleCloseBio = (e) => {
    e.stopPropagation();
    if (isBioOpen) {
      setIsBioOpen(false);
    }
  };

  return (
    <>
      <div className="member">
        <MemberImageContainer onMouseMove={handleMouseMove}>
          <MemberSVG
            restImageUrl={props.memberPhotoFormal.url || placeholder}
            hoverImageUrl={props.memberPhotoInformal.url || placeholder}
            restShape={props.backgrounds[0]}
            hoverShape={props.backgrounds[1]}
            resetFloatingBioPosition={() =>
              setFloatingBioPosition(offScreenPosition)
            }
            mobileBreakpoint={MOBILE_BREAKPOINT}
            changeHoverStatus={setIsHovered}
            memberName={props.memberName}
            onHover={isHovered}
          />
          <MemberBioToggle onClick={handleOpenBio}>
            <img src={bioSVG} />
          </MemberBioToggle>
          {/* <SocialLink>
            <a href="#" onClick={(e) => e.stopPropagation()}>
              <img src={socialSVG} />
            </a>
            <a href="#" onClick={(e) => e.stopPropagation()}>
              <img src={socialSVG} />
            </a>
            <a href="#" onClick={(e) => e.stopPropagation()}>
              <img src={socialSVG} />
            </a>
          </SocialLink> */}
          {isBioOpen && (
            <MemberMobileBio onClick={(e) => e.stopPropagation()}>
              <Row>
                <h3>A bit about me</h3>
                <CloseWrapper onClick={handleCloseBio}>
                  <img src={closeSVG} />
                </CloseWrapper>
              </Row>
              <p>{props.memberBio}</p>
            </MemberMobileBio>
          )}
        </MemberImageContainer>
        <RoleTitle>{props.memberRole}</RoleTitle>
        <p className="p2 center">{props.memberName}</p>
      </div>
      <MemberFloatingBio
        style={{
          left: floatingBioPosition.x,
          top: floatingBioPosition.y,
          transform: `scale(${isHovered ? 1 : 0})`,
        }}
      >
        <h3>A bit about me</h3>
        <p>{props.memberBio}</p>
      </MemberFloatingBio>
    </>
  );
};

export default Member;

const MemberImageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const MemberBioToggle = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(30, 34, 170, 0.08);

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;
const MemberFloatingBio = styled.div`
  display: none;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
  }

  position: absolute;
  top: 0;
  left: -720px;
  z-index: 10;
  max-width: 360px;
  padding: 20px 20px 34px;
  background-color: rgba(245, 245, 245, 0.88);
  mix-blend-mode: normal;
  backdrop-filter: blur(12px);

  & > h3 {
    margin: 0 0 10px;
    font-family: Clan, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  & > p {
    margin-bottom: 0;
    font-family: Clan news, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
  }
`;
const MemberMobileBio = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }

  position: absolute;
  bottom: 0;
  text-align: left;
  z-index: 9;
  padding: 20px 20px 34px;
  background: rgba(245, 245, 245, 0.88);
  mix-blend-mode: normal;
  backdrop-filter: blur(12px);

  h3 {
    margin: 0 0 10px;
    font-family: Clan, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  & > p {
    margin-bottom: 0;
    font-family: Clan news, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CloseWrapper = styled.div`
  cursor: pointer;
  background-color: white;
  width: 24px;
  height: 24px;
  display: flex;
  img {
    margin: auto;
  }
`;
const RoleTitle = styled.div`
  width: auto;
  margin-bottom: 5px;
  font-family: Clan, sans-serif;
  color: #540d6e;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
`;
// const SocialLink = styled.div`
//   position: absolute;
//   top: 8px;
//   left: 8px;
//   gap: 24px;

//   display: flex;
//   flex-direction: column;
//   & > a {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 48px;
//     height: 48px;
//     border-radius: 50%;
//     background-color: rgba(30, 34, 170, 0.08);
//   }
// `;

Member.propTypes = {
  memberName: PropTypes.string,
  memberRole: PropTypes.string,
  memberBio: PropTypes.string,
  memberPhoto: PropTypes.object,
  memberPhotoFormal: PropTypes.object,
  memberPhotoInformal: PropTypes.object,
  backgrounds: PropTypes.array,
};
