import normalizeRing from "flubber/src/normalize";
import { toPathString } from "flubber/src/svg";

// path is the svg path that forms the background shape used in the team page
// pollygon is the top mask to be applied to each specific shape in order to avoid strange cuts of the team members photos
const PATHS = [
  {
    path: "M195.828 308.657C195.828 308.657 286.449 275.531 241.479 191.007C209.391 109.824 244.76 101.15 225.985 42.0651C203.689 -28.0978 114.755 6.65287 81.1719 24.7453C54.0544 39.3546 -9.23482 89.4926 1.13702 168.745C8.22646 222.917 35.591 186.75 67.2664 277.583C103.167 348.977 195.828 308.657 195.828 308.657Z",
    polygon:
      "0 -40,256 -40,253.9993133544922 235.7545166015625,0.00031856849091127515 151.7088165283203",
  },
  {
    path: "M195.828 11.3432C195.828 11.3432 286.449 44.4688 241.479 128.993C209.391 210.177 244.76 218.85 225.985 277.935C203.689 348.098 114.755 313.347 81.1719 295.255C54.0544 280.645 -9.23482 230.507 1.13702 151.255C8.22647 97.0833 35.591 133.25 67.2664 42.4174C103.167 -28.9765 195.828 11.3432 195.828 11.3432Z",
    polygon:
      "0 -40,256 -40,253.9993133544922 84.24559020996094,0.0003180119092576206 168.2913360595703",
  },
  {
    path: "M58.172 11.3432C58.172 11.3432 -32.4489 44.4688 12.5213 128.993C44.6089 210.177 9.23952 218.85 28.0152 277.935C50.3114 348.098 139.245 313.347 172.828 295.255C199.946 280.645 263.235 230.507 252.863 151.255C245.773 97.0833 218.409 133.25 186.734 42.4174C150.833 -28.9765 58.172 11.3432 58.172 11.3432Z",
    polygon:
      "0 -40,256 -40,253.99974060058594 168.29078674316406,0.0006417680415324867 84.24579620361328",
  },
  {
    path: "M58.1719 308.657C58.1719 308.657 -32.449 275.531 12.5212 191.007C44.6088 109.824 9.23948 101.15 28.0152 42.0651C50.3113 -28.0978 139.245 6.65287 172.828 24.7453C199.946 39.3546 263.235 89.4926 252.863 168.745C245.773 222.917 218.409 186.75 186.734 277.583C150.833 348.977 58.1719 308.657 58.1719 308.657Z",
    polygon:
      "0 -40,256 -40,253.99974060058594 151.70912170410156,0.0007780133746564388 235.75440979003906",
  },
  {
    path: "M199.526 111.349C238.415 18.325 225.627 -12.5992 145.566 6.29211C71.9372 23.6657 16.9679 99.7788 3.24986 161.477C-8.11142 212.576 80.0619 175.476 21.2155 241.206C-37.6309 306.935 41.2331 337.426 139.079 312.156C212.642 293.158 266.438 247.334 252.91 205.199C239.383 163.065 168.764 184.935 199.526 111.349Z",
    polygon:
      "0 -40,256 -40,254.99951171875 218.52928161621094,1.0034300088882446 282.3486328125",
  },
  {
    path: "M56.4736 111.349C17.585 18.325 30.3733 -12.5992 110.434 6.29211C184.063 23.6657 239.032 99.7788 252.75 161.477C264.111 212.576 175.938 175.476 234.784 241.206C293.631 306.935 214.767 337.426 116.921 312.156C43.3575 293.158 -10.4381 247.334 3.08966 205.199C16.6174 163.065 87.2363 184.935 56.4736 111.349Z",
    polygon:
      "0 -40,256 -40,254.99639892578125 282.3489990234375,1.0001989603042603 218.5290985107422",
  },
  {
    path: "M17.3782 196.206C-27.9328 243.971 28.2967 308.049 94.3521 319.113C188.25 334.84 298.525 248.048 237.382 214.846C208.041 198.912 162.046 203.196 209.54 135.043C257.035 66.8912 196.438 42.4262 118.373 13.3013C40.3065 -15.8237 17.3782 10.3888 28.2964 54.0762C39.2146 97.7635 62.6892 148.441 17.3782 196.206Z",
    polygon:
      "0 -40,256 -40,254.99867248535156 240.26426696777344,1.0026096105575562 233.78065490722656",
  },
  {
    path: "M238.622 196.206C283.933 243.971 227.703 308.049 161.648 319.113C67.7504 334.84 -42.5247 248.048 18.6178 214.846C47.9594 198.912 93.9542 203.196 46.4595 135.043C-1.03513 66.8912 59.5615 42.4262 137.627 13.3013C215.693 -15.8237 238.622 10.3888 227.704 54.0762C216.785 97.7635 193.311 148.441 238.622 196.206Z",
    polygon:
      "0 -40,256 -40,254.99752807617188 233.78067016601562,1.0013741254806519 240.26402282714844",
  },
];

// normalize paths
for (const PATH of PATHS) {
  PATH.path = toPathString(normalizeRing(PATH.path, 12));
}

const getRandomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getRandomPaths = () => {
  const i = getRandomNumber(PATHS.length);
  let j;

  do {
    j = getRandomNumber(PATHS.length);
  } while (i === j);

  return [PATHS[i], PATHS[j]];
};
