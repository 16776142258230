import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../components/Button";
import HeroContainer from "../components/HeroContainer";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";
import SEO from "../components/SEO";
import { AnimatedRandomX } from "../components/Animations";
import MemberSection from "../components/MembersSection";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

export const pageQuery = graphql`
  query TeamQuery {
    prismicSeo(data: { name: { eq: "Mosano | Team" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    team: allPrismicTeamMember(filter: { data: { name: { ne: "Mosano" } } }) {
      nodes {
        data {
          name
          role
          bio
          active_team_member
          imagedraw
          photo {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  tracedSVGOptions: { color: "#444", threshold: 100 }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
          photo_formal {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  tracedSVGOptions: { color: "#444", threshold: 100 }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
          photo_informal {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  tracedSVGOptions: { color: "#444", threshold: 100 }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    bitmap2: prismicImage(uid: { eq: "team-1" }) {
      data {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    background2: prismicImage(uid: { eq: "team-2" }) {
      data {
        image {
          alt
          url
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

// @ts-ignore
const Team = (props: any) => {
  const bitmap2 =
    props.data.bitmap2.data.image.localFile.childImageSharp.gatsbyImageData ||
    [];
  const background2 = props.data.background2.data.image.url;

  const teamMembers = props.data.team.nodes.filter(
    (node: any) => node?.data?.active_team_member
  );
  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="hero-team ">
        <HeroContainer
          heroType="team"
          titleClass="full2"
          title="A team of problem solvers united by the pursuit of meaningful"
          specialTitle="digital experiences."
          paragraphClass="p1 right-copy white"
          paragraph="We are passionate about everything we do. We embrace and promote everyone’s expertise so that each one of us can be a mentor to someone. We all join forces to deliver the best experiences."
          divBlock="97"
          background={
            <GatsbyImage
              image={bitmap2}
              className="background-content"
              style={{ position: "inherit" }}
              alt={"background-image"}
            />
          }
        />
        <RandomBlockX className="div-block-99" />
        <RandomBlockX className="div-block-101" />
        <RandomBlockX className="div-block-100" />
        <RandomBlockX className="div-block-102">
          <br />
          <br />‍
        </RandomBlockX>
      </div>
      <div className="team-values" />
      <div className="team-members">
        <TaggedTextWithDescription
          containerType="hero center w-container"
          title="We Are MOSANO"
          titleClass="center"
          subtitle="Our team"
          body="Our workspace is actively shaped by the ones who show up day after day with their singularity and special humor. Meet our fantastic and unique elements that together turn ideas into reality."
          bodyClass="p2 center front-index"
        />
        <MemberSection team={teamMembers} />
      </div>
      <div>
        <div className="join-us">
          <RandomBlockX className="div-block-103" />
          <RandomBlockX className="div-block-104" />
          <div className="container-join-us">
            <div className="wrapper-join-us">
              <div className="div-block-78">
                <div className="cap-symbol-2">{"// "}</div>
                <div className="cap2">Join us</div>
              </div>
              <h2 className="heading full">Like what you see?</h2>
              <p className="p1 full join-us-message">
                We are always on the hunt for talented people.
                <br />
                Check if we&#x27;re in need of someone like you!
              </p>
              <Button
                buttonClass="button-container-copy"
                buttonHref="/join-us"
                buttomHrefClass="button-read-more white w-button"
                buttonLabel="see open vacancies"
                buttonLine="button-box"
              />
              <div className="background-content-3" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="work-with-us"
        style={{ backgroundImage: `url(${background2})` }}
      >
        <TaggedTextWithDescription
          containerType="work-with-us"
          title={
            <>
              If you made it this far...
              <br />
              You may have become a fan.
            </>
          }
          titleClass="center white"
          subtitle="Work with us"
          body="Just think about it, you are at least intrigued."
          bodyClass="p1 center white"
          capClass="study-cap-color"
        >
          <Button
            buttonClass="button-container-2"
            buttonHref="/contact"
            buttonHrefClass="button-2 w-button green"
            buttonLabel="let&#x27;s make something amazing together"
            buttonLine="button-box green"
          />
        </TaggedTextWithDescription>
      </div>
    </>
  );
};
export default Team;
