import React, {
  useLayoutEffect,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { motion, useAnimation } from "framer-motion";
import slugify from "slugify";
import {
  ON_HOVER_BACKGROUND_COLOR,
  REST_BACKGROUND_COLOR,
} from "../../styles/constants";
import useSVGPathMorph from "../../hooks/useSVGPathMorph";

/**
 * When the mouse hovers the SVG very quickly, can glitch the image transition.
 * When added the delay, it doesn't happen.
 */
const imageTransitionDelay = 0.07;

const shapeTransition = {
  type: "spring",
  duration: 0.5,
  stiffness: 500,
  damping: 50,
};

const shapeColorVariants = {
  start: {
    fill: REST_BACKGROUND_COLOR,
  },
  end: {
    fill: ON_HOVER_BACKGROUND_COLOR,
  },
};

const imageTransition = {
  type: "spring",
  duration: 0.5,
  delay: imageTransitionDelay,
};

const initialImageVariant = {
  start: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  end: {
    opacity: 0,
    scale: 0,
    y: 300,
  },
};

const onHoverImageVariant = {
  start: {
    opacity: 0,
    scale: 0,
    y: 300,
  },
  end: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
};

const MemberSVG = ({
  restImageUrl,
  hoverImageUrl,
  restShape,
  hoverShape,
  resetFloatingBioPosition,
  mobileBreakpoint,
  changeHoverStatus,
  memberName,
  onHover,
}) => {
  const [windowSize, setWindowSize] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [wasTapped, setWasTapped] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout>(undefined);
  const mobileAnimation = useAnimation();

  const shapes = useMemo(() => {
    return {
      initial: restShape?.path,
      whileHover: hoverShape?.path,
    };
  }, [restShape, hoverShape]);

  const [currentShape, setCurrentShape] = useState<string>(shapes["initial"]);
  const path = useSVGPathMorph(currentShape, shapeTransition);
  const [polygonShape, setPolygonShape] = useState<string>(
    currentShape === shapes["initial"]
      ? restShape?.polygon
      : hoverShape?.polygon
  );

  const handleImageHoverEnter = () => {
    if (windowSize > mobileBreakpoint) {
      changeHoverStatus(true);
    }
  };

  const handleImageHoverExit = () => {
    if (windowSize > mobileBreakpoint) {
      changeHoverStatus(false);
      resetFloatingBioPosition();
    }
  };

  const handleImageClick = () => {
    if (windowSize <= mobileBreakpoint) {
      setWasTapped(!wasTapped);
    }
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize(window.innerWidth);
      // Breakpoint reset
      if (window.innerWidth <= mobileBreakpoint) {
        changeHoverStatus(false);
        setIsMobile(true);
      } else {
        setWasTapped(false);
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize); // cleanup
  }, []);

  const handleTapOnSVG = useCallback(() => {
    setWasTapped((state) => !state);
    mobileAnimation.start(wasTapped ? "start" : "end");
    if (timerRef.current) clearTimeout(timerRef.current);
  }, [mobileAnimation, wasTapped, setWasTapped]);

  useEffect(() => {
    const showInitial = (!isMobile && !onHover) || (isMobile && !wasTapped);

    setCurrentShape(showInitial ? shapes["initial"] : shapes["whileHover"]); // this is necessary for the useSVGPathMorph hook
    setPolygonShape(showInitial ? restShape?.polygon : hoverShape?.polygon); // this is necessary for the TopMask animate points
  }, [onHover, isMobile, wasTapped]);

  // On mobile only, if the SVG was tapped on, we will reset the animation
  useEffect(() => {
    if (isMobile && wasTapped) {
      timerRef.current = setTimeout(() => {
        setWasTapped(false);
        mobileAnimation.start("start");
      }, 3000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isMobile, wasTapped, mobileAnimation]);

  return (
    <Svg
      viewBox={"0, -40, 256, 362"}
      xmlns="http://www.w3.org/2000/svg"
      {...(!isMobile
        ? {
            onMouseEnter: handleImageHoverEnter,
            onMouseLeave: handleImageHoverExit,
            onClick: handleImageClick,
            initial: "start",
            whileHover: "end",
          }
        : {
            onTap: handleTapOnSVG,
            animate: mobileAnimation,
          })}
    >
      <defs>
        <clipPath id={`mask-${slugify(memberName)}`}>
          <TopMask
            animate={{
              points: polygonShape,
            }}
          />
          <motion.path d={path} transition={shapeTransition} fill="none" />
        </clipPath>
      </defs>
      <g
        clipPath={`url(#mask-${slugify(memberName)})`}
        webkit-clippath={`url(#mask-${slugify(memberName)})`}
        moz-clippath={`url(#mask-${slugify(memberName)})`}
      >
        <motion.path
          d={path}
          variants={shapeColorVariants}
          transition={shapeTransition}
        />
        <Img
          variants={initialImageVariant}
          transition={imageTransition}
          xlinkHref={restImageUrl}
          preserveAspectRatio="xMidYMax slice"
        />
        <Img
          variants={onHoverImageVariant}
          transition={imageTransition}
          xlinkHref={hoverImageUrl}
          preserveAspectRatio="xMidYMax slice"
        />
      </g>
    </Svg>
  );
};

MemberSVG.propTypes = {
  restImageUrl: PropTypes.string,
  hoverImageUrl: PropTypes.string,
  resetFloatingBioPosition: PropTypes.func,
  mobileBreakpoint: PropTypes.number,
  hoverShape: PropTypes.object,
  restShape: PropTypes.object,
  changeHoverStatus: PropTypes.func,
  memberName: PropTypes.string,
  onHover: PropTypes.bool,
};

export default MemberSVG;

const Svg = styled(motion.svg)`
  height: 100%;
  width: 100%;
  fill: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;

const Img = styled(motion.image)`
  width: 100%;
  height: 100%;
  x: 0;
  y: -30;
`;

const TopMask = styled(motion.polygon)`
  x: 0;
  y: 0;
  width: 100%;
  fill: none;
`;
