import React, { useMemo } from "react";
import Member from "../Member";
import { getRandomPaths } from "../../utils/get-random-shape";
import PropTypes from "prop-types";
import "../../../assets/css/webflowMosanoStyles.css";

const MemberSection = ({ team }) => {
  return (
    <div className="container-team-members">
      {team.map(({ data: member }, index) => {
        const background = useMemo(() => getRandomPaths(), []);

        return (
          <Member
            key={`member-${member.name}-${index}`}
            memberName={member.name}
            memberRole={member.role}
            memberBio={member.bio}
            memberPhoto={member.photo}
            memberPhotoFormal={member.photo_formal}
            memberPhotoInformal={member.photo_informal}
            backgrounds={background}
          />
        );
      })}
    </div>
  );
};

MemberSection.propTypes = {
  team: PropTypes.array,
};

export default MemberSection;
